import React from "react"
import { Component, Fragment } from "react"
import { Link } from "gatsby"
import CTAUk from "../../general/ctaUk"
import { scrollToElement } from "../../../../utility/utils"
import PageNotFound from "../../404"


class FAQs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      panelData: [],
      faqs: null,
      getLocalFaqs: [],
    }
    this.activateTab = this.activateTab.bind(this)
  }

  activateTab(index) {
    this.setState(prev => ({
      activeTab: prev.activeTab === index ? -1 : index,
    }))
  }

  getdata = async firebase => {
    const data = await firebase.FireBase()
    this.setState({
      getLocalFaqs: firebase.getFaqs(),
    })
    return data
  }

  componentDidMount = async () => {
    setTimeout(() => {
      scrollToElement()
      window.addEventListener("scroll", function () {
        scrollToElement()
      })
    }, 0)

    import("../../../../../firebase").then(firebase => {
      this.getdata(firebase)
    })

    this.setState({
      faqs: localStorage.getItem("kudaFaqs"),
    })
  }

  panels = []
  render() {
    if (this.state.faqs !== null) {
      this.panels = JSON.parse(this.state.faqs)
    } else {
      this.panels = this.state.getLocalFaqs
    }

    const { activeTab } = this.state
    return (
      <Fragment>
        <div className="kuda-inner--heading kuda-disclaimer--section">
          <div className="kuda-section--inner general-style">
            <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
              <Link to="/en-gb/legal/" className="color-black mb-3 mt-4 back-page">
                <div className="align-self-center mr-2">
                  <div className="f-14">
                    Legal
                  </div>
                </div>
                <span className="mr-2">
                  <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                      fill="#C7C7CC"
                    ></path>
                  </svg>
                </span>
                <span className="align-self-center f-14 mr-2">United Kingdom</span>
                <span className="mr-2">
                  <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                      fill="#C7C7CC"
                    ></path>
                  </svg>
                </span>
                <span className="align-self-center f-14 text-bold text-left">Frequently Asked Questions</span>
              </Link>
              <div className="legal-left">
              <PageNotFound/>
              </div>
            </div>
           
          </div>
        </div>
        <CTAUk />
      </Fragment>
    )
  }
}
export default FAQs
